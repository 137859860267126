import { MortgageSignpost } from '@components/Signpost';

import { BlogLinks } from '@constants/index';

import { environmentFor } from '@utils/environment';

import { ContentBlock } from '../../components/ContentBlock';


export const Finances = () => {
  const ENVIRONMENT = environmentFor(window.location.href);
  const isDevEnvironment = ['dev', 'local', 'staging'].includes(ENVIRONMENT);

  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:flex-row md:items-start md:gap-14 md:px-10">
        <div id="column" className="flex flex-col gap-10 md:mt-5 md:basis-1/2 md:gap-14">
          <MortgageSignpost isDevEnvironment={isDevEnvironment} />
          {/* <hr className="w-full border-stone-200 md:hidden" />
          <ContentBlock
            title="Mortgage rate checker"
            content="Work out what size of mortgage you could afford and how much mortgage repayments are for different loans"
            link={BlogLinks.MORTGAGE_CALCULATOR}
            buttonText="Use the calculator"
          /> */}
        </div>
        <div id="column" className="flex flex-col gap-10 md:basis-1/2 md:gap-14">
          <hr className="w-full border-stone-200 md:hidden" />
          <ContentBlock
            title="What to expect"
            content="Personalised loan options and advice, transparency and clear communication, regular updates on progress of application and approval."
            link={BlogLinks.MORTGAGE_PROCESS}
            buttonText="Read our guide to the mortgage process"
            showBoxOnDesktop
          />
        </div>
      </div>
    </>
  );
};
