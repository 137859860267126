import { Link } from 'react-router-dom';

import DefaultImage from '@images/default_image_05-2024.webp';
import ServiceLogos from '@images/service-logos.png';

export const AllServices = () => {
  return (
    <div className="grid grid-cols-1 overflow-hidden rounded-3xl bg-white shadow-homeLarge md:grid-cols-2">
      <div className="col-span-1 flex flex-col items-start gap-7 p-7 md:gap-8 md:p-14">
        <h3 className="text-3xl font-medium md:text-4xl">Everything you need to make it home</h3>
        <p className="text-xl">
          Make managing your home simple and rewarding, from utilities and services to cleaners and
          tradespeople.
        </p>
        <img src={ServiceLogos} alt="Service logos" className="max-w-[256px]" />
        <div className="flex flex-col items-center gap-5 lg:flex-row">
          <a
            href="https://home.cc/services"
            target="_blank"
            rel="noreferrer"
            className="mt-auto inline-block rounded-2xl border-2 border-black bg-black px-10 py-3 text-center font-semibold text-white hover:border-gray-700 hover:bg-gray-700 md:px-12 md:py-4"
          >
            All services
          </a>
          <span className="hidden lg:flex">or</span>
          <Link
            to="#home-team"
            className=" mt-auto flex gap-3 rounded-2xl border-2 border-black bg-white px-10 py-3 text-center font-semibold text-black transition-all hover:bg-black hover:text-white md:px-12 md:py-4"
          >
            Speak to us
          </Link>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${DefaultImage})`,
        }}
        className="col-span-1 bg-cover bg-center bg-no-repeat pb-[60%]"
      />
    </div>
  );
};
