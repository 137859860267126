import { Button } from '@components/Button';
import { Homebox } from '@components/Homebox';

import { Broadband } from '@images/icons';

export const NoAddressTemp = () => {
  return (
    <div>
      <header className="mx-auto my-4 flex w-full max-w-3xl flex-col items-center justify-center gap-8 px-5 pb-12 pt-8">
        <h2 className="flex items-baseline gap-2 text-center  text-3xl font-medium md:text-4xl">
          <span>Compare</span>
          <Broadband className="h-14 fill-brand" />
          <span>broadband deals</span>
        </h2>
        <p className="text-center">
          Home can help find the perfect tariff for your place. And even makes switching a breeze.
          Switch yourself below or speak to your Home concierge and we'll do the hard work for you.
        </p>
      </header>

      <main className="box-border w-full bg-brand px-5 pb-20 pt-8 md:pt-16">
        <div className="mx-auto box-border w-full max-w-6xl">
          <div className="flex w-full flex-col items-center justify-center gap-10 md:gap-14">
            <Homebox serviceType="broadband" includeBundles />

            <div className="mx-auto flex max-w-4xl flex-col items-center justify-center gap-7 px-5 pb-6 md:px-0">
              <h2 className="text-center text-4xl font-medium text-white">
                Don't forget, your Home concierge is on hand to help
              </h2>
              <p className="text-center text-white">
                We're on hand to take the hassle out of tasks like setting up your broadband. First
                we'll search the market for you and find your perfect tariff and then, when you’re
                ready, we can even take care of the setup. Just a reminder - this is a completely
                free service. Read more about how it works here.
              </p>
              <Button onClick={() => window.LiveChatWidget.call('maximize')} customStyles="mt-3">
                Chat to your concierge
              </Button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
