import { SingleSnow } from '@images/icons';

export const Calculating = () => {
  return (
    <div className="relative h-20 w-20">
      <div className="absolute right-[5px] top-[5px] transform">
        <SingleSnow className="an animateSpinReverse h-6 w-6" />
      </div>
      <div className="absolute bottom-[10px] left-[10px] transform">
        <SingleSnow className="animateSpin h-12 w-12 duration-[2000]" />
      </div>
    </div>
  );
};
