import { ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { TickIsolated } from '@images/icons';

import { Checklist } from '../Checklist';

type CardProps = {
  id: string;
  isActive: boolean;
  onToggle: () => void;
  icon: ReactNode;
  title: string;
  content: ReactNode;
  hideBorder: boolean;
  topRadius: boolean;
  bottomRadius: boolean;
  tasks: any[];
  accordionActivated?: boolean;
  propertyId: string;
};

const Completion = ({
  totalTasks,
  completeTasks,
}: {
  totalTasks: number;
  completeTasks: number;
}) => {
  if (completeTasks === totalTasks) {
    return (
      <span className="flex items-center gap-1 font-heading text-lg font-medium md:text-xl">
        <TickIsolated className="h-5 fill-primary" /> Completed
      </span>
    );
  }
  if (completeTasks > 0) {
    return (
      <span className="rounded-full bg-primary px-3 py-0.5 font-heading text-base font-medium text-white">
        In progress
      </span>
    );
  }
  return (
    <span className="rounded-full bg-primary px-3 py-0.5 font-heading text-base font-medium text-white">
      Not started
    </span>
  );
};

export const Card = ({
  id,
  isActive,
  icon,
  onToggle,
  title,
  content,
  hideBorder,
  topRadius,
  bottomRadius,
  tasks,
  accordionActivated,
  propertyId,
}: CardProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const timeoutDuration = !accordionActivated ? 750 : 0;

    if (contentRef.current) {
      timeout = setTimeout(() => {
        if (contentRef.current) {
          const contentHeight = contentRef.current.scrollHeight;
          setMaxHeight(isActive ? contentHeight : 0);

          const focusableElements = contentRef.current.querySelectorAll<HTMLElement>(
            'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
          );

          focusableElements.forEach(element => {
            if (!isActive) {
              element.setAttribute('tabindex', '-1');
            } else {
              if (element.getAttribute('tabindex') === '-1') {
                element.removeAttribute('tabindex');
              }
            }
          });
        }
      }, timeoutDuration);
    }

    return () => clearTimeout(timeout);
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollToCard = () => {
    const cardElement = cardRef.current;
    if (cardElement) {
      const yOffset = -50;
      const rect = cardElement.getBoundingClientRect();
      const yPosition = rect.top + window.scrollY + yOffset;
      window.scrollTo({ top: yPosition, behavior: 'smooth' });
    }
  };

  const handleHeaderClick = () => {
    if (!isActive) {
      onToggle();
      // Scroll after toggling the active state
      setTimeout(() => {
        scrollToCard();
      }, 300);
    } else {
      setTimeout(() => {
        scrollToCard();
      }, 300);
    }
  };

  return (
    <div
      id={id}
      ref={cardRef}
      className={twMerge(
        'z-10 w-[calc(100%-30px)] overflow-hidden border-b border-neutral-300 bg-secondary transition-all duration-300 md:w-[calc(100%-65px)]',
        isActive &&
          'z-20 w-full rounded-2xl bg-white shadow-homeLarge duration-500 md:w-[calc(100%-25px)] md:rounded-3xl',
        (isActive || hideBorder) && 'border-none',
        topRadius && 'rounded-t-2xl md:rounded-t-3xl',
        bottomRadius && 'rounded-b-2xl md:rounded-b-3xl'
      )}
    >
      <button
        className={twMerge('flex w-full items-center justify-between px-5 py-6 md:pl-7 md:pr-10')}
        onClick={handleHeaderClick}
        aria-expanded={isActive}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <div className="flex items-center gap-2 md:gap-5">
          <span className="flex w-12 justify-center">{icon}</span>
          <h3 className="text-xl font-medium md:text-2xl">{title}</h3>
        </div>
        <Completion
          totalTasks={tasks.length}
          completeTasks={tasks.filter(task => task.status === 'COMPLETED').length}
        />
      </button>
      <div
        id={`${id}-content`}
        role="region"
        aria-labelledby={`${id}-header`}
        className={twMerge(
          'transition-all duration-300 ease-in-out',
          isActive ? 'opacity-100' : 'opacity-0',
          'overflow-hidden'
        )}
        style={{ maxHeight: isActive ? `${maxHeight}px` : '0px' }}
      >
        <div ref={contentRef}>
          {content}
          {tasks.length > 0 && <Checklist tasks={tasks} propertyId={propertyId} />}
        </div>
      </div>
    </div>
  );
};
