import { Removals as RemovalsIcon } from '@images/icons';
import DefaultImage from '@images/moving.webp';

export const Removals = () => {
  return (
    <div className="grid grid-cols-1 overflow-hidden rounded-3xl bg-white shadow-homeLarge md:grid-cols-2">
      <div
        style={{
          backgroundImage: `url(${DefaultImage})`,
        }}
        className="col-span-1 bg-cover bg-center bg-no-repeat pb-[60%]"
      />
      <div className="col-span-1 flex flex-col items-start gap-7 p-7 md:gap-8 md:p-14">
        <h3 className="w-full text-left text-3xl font-medium">
          <RemovalsIcon className="inline-block h-16 fill-primary pr-3" /> Moving home?
        </h3>
        <p className="-mt-2 text-xl">
          We work with some of the best van hire services in the UK to help you move home with ease.
          <br />
          <br />
          Use code <span className="inline-block bg-gray-100 px-2 py-1 text-home">HOME</span> when
          booking.
        </p>

        <div className="flex flex-col items-center gap-5 lg:flex-row">
          <a
            href="https://www.anyvan.com/partners/home"
            target="_blank"
            rel="noreferrer"
            className="mt-auto inline-block rounded-2xl border-2 border-black bg-black px-10 py-3 text-center font-semibold text-white hover:border-gray-700 hover:bg-gray-700 md:px-12 md:py-4"
          >
            AnyVan
          </a>
          <span className="hidden lg:flex">or</span>
          <a
            href="https://partners.rentalcar.com/home/"
            target="_blank"
            rel="noreferrer"
            className="mt-auto inline-block rounded-2xl border-2 border-black bg-black px-10 py-3 text-center font-semibold text-white hover:border-gray-700 hover:bg-gray-700 md:px-12 md:py-4"
          >
            Enterprise
          </a>
        </div>
      </div>
    </div>
  );
};
