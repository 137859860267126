import { Link } from 'react-router-dom';

import { Heading } from '@components/Heading';

import DefaultImage from '@images/default_image_05-2024.webp';

import { HomeCarousel } from '@pages/dashboard/Intro/Carousel';

import Map from './Map';
import { QuickTip } from './QuickTip';

type StatusItem = {
  title: string;
  children: React.ReactNode;
  href: string;
  target?: string;
};

type IntroProps = {
  statusItems: StatusItem[];
  title: string;
  pillText?: string;
  address: string;
  images: string[];
  postcode?: string;
};

export const Intro = ({ statusItems, title, pillText, address, images, postcode }: IntroProps) => (
  <div className="mx-auto grid min-h-[500px] max-w-[1800px] grid-cols-1 md:grid-cols-2">
    <div className="mx-auto box-border flex w-full max-w-xl flex-col items-center justify-center gap-5 px-5 py-16">
      <Heading>{title}</Heading>
      {pillText && (
        <span className="block rounded-full bg-primary px-5 py-1.5 text-sm font-medium text-white">
          {pillText}
        </span>
      )}
      <span className="text-center text-xl font-medium">{address}</span>
      <QuickTip />
      <div className="flex w-full max-w-96 justify-around py-5">
        {statusItems.map(({ href, children, title, target }, index) => {
          return (
            <Link
              className="group flex cursor-pointer flex-col items-center justify-center gap-3 no-underline"
              tabIndex={0}
              to={href}
              key={index}
              target={target}
            >
              <div className="flex h-[70px] w-[70px] items-center justify-center rounded-full bg-brand shadow-md transition-all duration-300 group-hover:bg-white">
                {children}
              </div>
              <h3 className="border-b-2 border-transparent text-xl font-medium transition-all duration-300 group-hover:border-brand">
                {title}
              </h3>
            </Link>
          );
        })}
      </div>
    </div>
    {!!postcode ? (
      <Map postcode={postcode} />
    ) : images.length > 1 ? (
      <HomeCarousel images={images} address={address} />
    ) : (
      <div
        style={{ backgroundImage: `url(${images[0] || DefaultImage})` }}
        className="relative z-10 w-full bg-cover bg-center pb-[66.66%] md:mt-0 md:pb-0"
        data-testid="home-image"
      />
    )}
  </div>
);
