import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { Button, LinkButton } from '@components/Button';
import { Spinner } from '@components/Spinner';

import { selectProfile } from '@features/profile/profile';

import { HomeboxBroadbandProduct, useBroadbandComparison } from '@hooks/useBroadbandComparison';

import { formatSpeed } from '@utils/formatBroadbandSpeed';

import { ReactComponent as LandlineIcon } from './landline.svg';
import { ReactComponent as WifiIcon } from './wifi.svg';

const Attribute = ({ children, label }: { children: React.ReactNode; label: string }) => (
  <div className="flex flex-col items-center gap-1">
    {children}
    <span className="text-center text-gray-500">{label}</span>
  </div>
);

const RadioButton = ({ selected, onChange, label }: any) => {
  return (
    <div className="flex cursor-pointer items-center gap-3" onClick={onChange}>
      <input type="radio" className="hidden" checked={selected} onChange={onChange} />
      <div
        className={twMerge(
          'h-5 w-5 cursor-pointer rounded-full border-2 border-gray-700',
          selected ? 'bg-gray-700' : 'bg-transparent'
        )}
      />
      <span className="font-medium text-gray-700">{label}</span>
    </div>
  );
};

const Product = ({
  broadband,
  contractLength,
  downloadLimit,
  downloadSpeed,
  landLine,
  monthlyCost,
  outlink,
  productName,
  supplierImage,
}: HomeboxBroadbandProduct) => {
  const { speed, unit } = formatSpeed(downloadSpeed);

  return (
    <div className="flex flex-col bg-white">
      <div className="flex flex-col items-center justify-between border-b border-gray-200 px-8 py-4 sm:flex-row">
        <div className="flex items-center gap-3">
          <div
            style={{ backgroundImage: `url(${supplierImage})` }}
            className="h-12 w-28 rounded-lg bg-contain bg-center bg-no-repeat"
          />
        </div>
        <h4 className="mb-4 mt-2 text-lg font-medium sm:my-0">{productName}</h4>
        <div className="flex items-center gap-5">
          {broadband && <WifiIcon />}
          {landLine && <LandlineIcon />}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-2 gap-y-6 px-4 py-6 sm:grid-cols-4 md:grid-cols-5">
        <Attribute label="Average UK Speed">
          <span className="font-heading text-2xl font-medium">
            {speed}&nbsp;{unit}
          </span>
        </Attribute>
        <Attribute label="Downloads">
          <span className="font-heading text-2xl font-medium">
            {downloadLimit >= 5000000 ? 'Unlimited' : downloadLimit}
          </span>
        </Attribute>
        <Attribute label="Contract">
          <span className="font-heading text-2xl font-medium">{contractLength} Months</span>
        </Attribute>
        <Attribute label="Monthly Cost">
          <span className="font-heading text-2xl font-medium">£{monthlyCost} p/m</span>
        </Attribute>
        <div className="col-span-2 flex justify-center sm:col-span-4 md:col-span-1">
          <LinkButton href={outlink} target="_blank" customStyles="!text-base">
            Choose deal
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

type AllProductsProps = {
  minSpeed?: number;
  clearFilters?: () => void;
};

const PAGE_SIZE = 10;

export const AllProducts = ({ minSpeed, clearFilters }: AllProductsProps) => {
  const { value: profile } = useSelector(selectProfile);
  const postcode = profile?.properties?.[0]?.postcode ?? '';

  const [pagination, setPagination] = useState({ start: 0, end: PAGE_SIZE, page: 1 });
  const [speed, setSpeed] = useState<number>(minSpeed || 0);
  const [contractLength, setContractLength] = useState(0);

  const { data, error, loading } = useBroadbandComparison(
    postcode,
    pagination.start,
    pagination.end,
    { speed, contractLength }
  );

  const totalDeals = data?.totalCount ?? 0;
  const totalPages = Math.ceil(totalDeals / PAGE_SIZE);

  const handlePageChange = (direction: number) => {
    const newPage = pagination.page + direction;
    setPagination({
      start: pagination.start + PAGE_SIZE * direction,
      end: pagination.end + PAGE_SIZE * direction,
      page: newPage,
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pagination.page]);

  useEffect(() => {
    if (minSpeed !== undefined) {
      setSpeed(minSpeed);
    }
  }, [minSpeed]);

  return (
    <main className="mx-auto mb-10 w-full max-w-7xl px-5">
      <div className="flex flex-col items-center justify-center gap-10 pb-8 md:gap-14">
        {loading && (
          <div className="py-20">
            <Spinner colour="black" />
          </div>
        )}
        {error && <p>Something went wrong. Please try again later.</p>}

        {!!data && (
          <div className="flex w-full flex-col gap-10 lg:flex-row">
            <aside className="flex flex-col items-start gap-10 sm:flex-row lg:basis-[240px] lg:flex-col lg:pl-2">
              <div className="flex flex-col gap-3 md:min-w-[200px]">
                <span className="text-lg font-medium">Average speed</span>
                <hr className="w-full border-gray-300" />
                <RadioButton
                  selected={speed === 15}
                  onChange={() => (speed === 15 ? setSpeed(0) : setSpeed(15))}
                  label="15MB+"
                />
                <RadioButton
                  selected={speed === 20}
                  onChange={() => (speed === 20 ? setSpeed(0) : setSpeed(20))}
                  label="20MB+"
                />
                <RadioButton
                  selected={speed === 50}
                  onChange={() => (speed === 50 ? setSpeed(0) : setSpeed(50))}
                  label="50MB+"
                />
                <RadioButton
                  selected={speed === 100}
                  onChange={() => (speed === 100 ? setSpeed(0) : setSpeed(100))}
                  label="100MB+"
                />
                <RadioButton
                  selected={speed === 250}
                  onChange={() => (speed === 250 ? setSpeed(0) : setSpeed(250))}
                  label="250MB+"
                />
                <RadioButton
                  selected={speed === 500}
                  onChange={() => (speed === 500 ? setSpeed(0) : setSpeed(500))}
                  label="500MB+"
                />
                <RadioButton
                  selected={speed === 999}
                  onChange={() => (speed === 999 ? setSpeed(0) : setSpeed(999))}
                  label="1GB+"
                />
              </div>
              <div className="flex flex-col gap-3 md:min-w-[200px]">
                <span className="text-lg font-medium">Contract length</span>
                <hr className="w-full border-gray-300" />
                <RadioButton
                  selected={contractLength === 12}
                  onChange={() =>
                    contractLength === 12 ? setContractLength(0) : setContractLength(12)
                  }
                  label="12 Months"
                />
                <RadioButton
                  selected={contractLength === 18}
                  onChange={() =>
                    contractLength === 18 ? setContractLength(0) : setContractLength(18)
                  }
                  label="18 Months"
                />
                <RadioButton
                  selected={contractLength === 24}
                  onChange={() =>
                    contractLength === 24 ? setContractLength(0) : setContractLength(24)
                  }
                  label="24 Months"
                />
              </div>
              <button
                onClick={() => clearFilters && clearFilters()}
                className="flex gap-3 rounded-2xl border-2 border-black bg-transparent px-10 py-2 text-center text-center text-lg font-medium text-black transition-all hover:bg-black hover:text-white md:px-12 md:py-4"
              >
                Clear filters
              </button>
            </aside>
            <div className="flex w-full flex-col gap-8">
              <div className="flex w-full items-center justify-between">
                <p className="text-lg">
                  Showing{' '}
                  <span className="font-medium">
                    {data.totalCount} of {data.stats.totalUnfiltered}
                  </span>{' '}
                  deals
                  {!!minSpeed && (
                    <span className="font-medium">
                      &nbsp;matching your broadband wizard bandwidth suggestions.&nbsp;
                    </span>
                  )}
                </p>
              </div>
              {data.products.length === 0 && (
                <div className="flex w-full flex-col gap-5 py-16 text-center text-2xl font-medium">
                  No results found
                </div>
              )}
              <div className="flex flex-col gap-8">
                {data.products.map((product, index) => (
                  <Product key={`${product.productName}-${index}`} {...product} />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {totalDeals > 25 && (
        <>
          {totalPages > 1 && (
            <p className="mt-10 block text-center md:hidden">
              Page {pagination.page} of {totalPages}
            </p>
          )}
          <div className="flex items-center justify-between gap-8 md:mt-8">
            <div className="mx-auto w-full max-w-60">
              <Button onClick={() => handlePageChange(-1)} disabled={pagination.page === 1}>
                Previous
              </Button>
            </div>
            {totalPages > 1 && (
              <p className="hidden md:block">
                Page {pagination.page} of {totalPages}
              </p>
            )}
            <div className="mx-auto w-full max-w-60">
              <Button onClick={() => handlePageChange(1)} disabled={pagination.page >= totalPages}>
                Next
              </Button>
            </div>
          </div>
        </>
      )}
    </main>
  );
};
