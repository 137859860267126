import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Picture } from '@images/icons';

import { Arrow } from './Arrow';
import { LazyImage } from './LazyImage';

type CarouselProps = {
  images: string[];
  address: string;
};

export const HomeCarousel = ({ images }: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleChange = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative z-10 w-full" data-testid="home-carousel">
      <Carousel
        className="home-carousel pb-[66.7%] md:h-full md:pb-0 lg:min-h-[600px]"
        infiniteLoop={true}
        onChange={handleChange}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        showThumbs={false}
        renderArrowNext={(clickHandler: () => void) => <Arrow handleClick={clickHandler} />}
        renderArrowPrev={(clickHandler: () => void) => <Arrow handleClick={clickHandler} isLeft />}
      >
        {images.map((image, index) => (
          <div key={`slide-${index}`}>
            <LazyImage image={image} index={index} currentIndex={currentIndex} />
          </div>
        ))}
      </Carousel>
      <div className="absolute bottom-0 left-0 right-0 z-10 flex items-center justify-center p-5 md:justify-between">
        <div className=" flex items-center gap-3 rounded-3xl bg-white px-3 py-1 text-xs text-primary md:px-4 md:py-2 md:text-sm">
          <Picture className="w-5 fill-primary sm:w-[23px]" />
          <span>
            {currentIndex + 1} of {images.length}
          </span>
        </div>
      </div>
    </div>
  );
};
