import React from 'react';

import { LinkButton } from '@components/Button';
import { Spinner } from '@components/Spinner';

import { Routes } from '@constants/index';

import { HomeboxBroadbandProduct, useBroadbandComparison } from '@hooks/useBroadbandComparison';

import { Broadband as BroadbandIcon } from '@images/icons';

import { formatSpeed } from '@utils/formatBroadbandSpeed';

type BroadbandProps = {
  postcode: string;
};

const Deal = React.memo(
  ({ supplierImage, downloadSpeed, effectiveMonthlyCost, outlink }: HomeboxBroadbandProduct) => {
    const { speed, unit } = formatSpeed(downloadSpeed);

    return (
      <div className="flex w-full flex-col gap-6">
        <div className="flex flex-col items-center justify-center gap-6 sm:flex-row md:justify-start">
          <div
            style={{ backgroundImage: `url(${supplierImage})` }}
            className=" h-10 w-24 rounded-lg bg-contain bg-center bg-no-repeat "
          />
          <div className="flex items-end gap-1 font-heading font-medium leading-none">
            <span className="-mb-0.5 text-3xl md:-mb-1">{speed}</span>
            {unit}
          </div>
          <div className="flex items-end gap-1 font-heading font-medium leading-none">
            <span className="-mb-0.5 text-3xl md:-mb-1">£{effectiveMonthlyCost}</span>
            Per month
          </div>
        </div>
        <div className="flex w-full justify-center md:justify-start">
          <a
            href={outlink}
            target="_blank"
            rel="noreferrer"
            className="flex w-auto gap-3 rounded-2xl border-2 border-black bg-black px-5 py-1 text-center font-medium text-white transition-all hover:border-gray-700 hover:bg-gray-700 md:px-10 md:py-3"
          >
            Get deal
          </a>
        </div>
      </div>
    );
  }
);

export const Broadband = ({ postcode }: BroadbandProps) => {
  const { data, error, loading } = useBroadbandComparison(postcode, 0, 250);

  if (loading)
    return (
      <div className="flex w-full items-center justify-center px-8 pb-20 pt-32">
        <Spinner colour="black" />
      </div>
    );

  if (error || !data) return null;

  const {
    recommendations: { cheapestProduct, fastestProduct },
  } = data;

  const { speed, unit } = formatSpeed(fastestProduct.downloadSpeed);

  return (
    <div className="flex min-h-[524px] w-full flex-col items-center gap-5 md:items-start">
      <h4 className="flex flex-col items-center justify-center gap-4 text-2xl font-medium md:flex-row">
        <BroadbandIcon className="h-10 w-10 fill-primary" />
        <span className="text-center md:text-left">
          You can get up to {speed}
          {unit} broadband!
        </span>
      </h4>

      <p className="text-center font-medium md:text-left">
        We&apos;ve checked the market for you at <span className="underline">{postcode}</span>.
        Compare plans and get your home connected.
      </p>
      <div className="flex flex-col gap-7">
        <div className="flex flex-col items-center gap-2 md:items-start">
          <h4 className="text-center text-xl font-medium md:text-left">Your cheapest deal:</h4>
          <Deal {...cheapestProduct} />
        </div>
        <div className="flex flex-col items-center gap-2 md:items-start">
          <h4 className="text-center text-xl font-medium md:text-left">Your fastest deal:</h4>
          <Deal {...fastestProduct} />
        </div>
      </div>
      <a
        href={Routes.SERVICES_BROADBAND}
        rel="noreferrer"
        className="text-center underline hover:no-underline md:text-left"
      >
        View all tariffs ({data.totalCount})
      </a>
    </div>
  );
};
