import { useState } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { LinkButton } from '@components/Button';

import { ProductLinks } from '@constants/index';

import { selectProfile } from '@features/profile/profile';

import BundleLogos from '@images/bundles-logos.png';
import BundlesIcons from '@images/bundles_icons.svg';
import { TickIsolated } from '@images/icons';

import { Broadband } from '../../components/Broadband';
import { Energy } from '../../components/Energy';

export const GetMoveReady = () => {
  const [showBundles, setShowBundles] = useState(false);

  const { value: profile } = useSelector(selectProfile);

  const postcode = profile?.properties[0]?.postcode || '';

  return (
    <>
      <div className="flex flex-col gap-8 px-7 pt-5 md:px-10">
        <div className="flex flex-col items-center gap-3">
          <span className="font-bold">To do</span>
          <h4 className="text-2xl font-medium">Set up your Home</h4>
          <p className="max-w-3xl text-center">
            Home makes getting everything ready for move in day a breeze saving you hours of hassle.
            Create a bundle for one easy bill or set up individually to suit you.
          </p>
          <label className="inline-flex w-full max-w-[340px] cursor-pointer items-center gap-4 py-4">
            <input
              type="checkbox"
              id="terms"
              className="peer sr-only"
              onChange={() => setShowBundles(!showBundles)}
            />
            <span
              className={twMerge('flex-1 select-none text-center', !showBundles && 'font-semibold')}
            >
              Individual bills
            </span>
            <div
              className={twMerge(
                'relative h-9 w-[68px] rounded-full bg-home shadow-sm transition-all duration-300',
                'peer-checked:bg-primary'
              )}
            >
              <div
                className={twMerge(
                  'absolute left-[2px] top-0.5 flex h-8 w-8 items-center justify-center rounded-full bg-white transition-all',
                  showBundles && 'translate-x-[32px]'
                )}
              >
                <TickIsolated
                  className={twMerge(
                    'w-5 transition-all',
                    showBundles ? 'fill-primary' : 'fill-home'
                  )}
                />
              </div>
            </div>
            <span
              className={twMerge('flex-1 select-none text-center', showBundles && 'font-semibold')}
            >
              Bundle my bills
            </span>
          </label>
        </div>
        {!!showBundles ? (
          <div className="flex w-full flex-col items-center gap-5 border-t border-stone-200 py-12 md:gap-7">
            <img
              src={BundlesIcons}
              alt="Bundles"
              className="w-full max-w-[266px] select-none object-cover"
              aria-hidden
            />
            <h4 className="max-w-sm text-center text-3xl font-medium md:max-w-2xl">
              Save time and money with a Home bills bundle
            </h4>
            <p className="max-w-sm text-center md:max-w-2xl">
              One payment, all your bills sorted. Create a bundle of all your bills to save more and
              simplify your home admin with just one easy bill to manage. Living with other bill
              payers? You can split the bills between you too!
            </p>
            <img src={BundleLogos} alt="Bundles" className="w-full max-w-sm object-cover" />
            <LinkButton href={ProductLinks.BUNDLES} target="_blank">
              Build my Home bundle
            </LinkButton>
          </div>
        ) : (
          <div className=" flex flex-col border-b border-stone-200 md:flex-row md:items-start">
            <div className="w-full border-t border-stone-200 py-10 md:basis-1/2 md:pr-12">
              <Energy postcode={postcode} />
            </div>
            <div className="w-full border-t border-stone-200 py-10 md:basis-1/2 md:border-l md:pl-12">
              <Broadband postcode={postcode} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
