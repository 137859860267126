import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { ProductLinks, WizardSteps } from '@constants/index';

import { selectProfile } from '@features/profile/profile';
import { MoveType } from '@features/profile/profile.types';

import { Bulb, Conveyancing, Insurance, Removals, StoreBag, WhatsApp } from '@images/icons';

import { useWizard } from '@pages/dashboard/MoveWizard/wizards';

interface Tip {
  id: string;
  isVisible: boolean;
  text: string;
  link?: string;
  openCard?: WizardSteps;
  iconBackground?: string;
  icon: React.ReactNode;
}

export const QuickTip: React.FC = () => {
  const { value: profile } = useSelector(selectProfile);
  const [visibleTip, setVisibleTip] = useState<Tip | null>(null);
  const [isCollapsing, setIsCollapsing] = useState(false);

  const steps = useWizard();

  const moveType = profile?.properties?.[0]?.status;

  const TIPS: Tip[] =
    moveType === MoveType.TENANCY
      ? [
          {
            id: 'whatsapp',
            isVisible: true,
            text: 'Add us on WhatsApp 🙌',
            link: 'https://wa.me/447535742914',
            iconBackground: 'bg-[#27D045]',
            icon: <WhatsApp className="ml-0.5 mt-0.5 h-6 fill-white" />,
          },
          {
            id: 'tenants-insurance',
            isVisible:
              steps.find(step => step.id === WizardSteps.insurance)?.tasks[0]?.status !==
              'COMPLETED',
            text: 'Get instant conveyancing quotes',
            openCard: WizardSteps.conveyancing,
            iconBackground: 'bg-home',
            icon: <Insurance className="mt-0.5 h-6 fill-white" />,
          },
          {
            id: 'removals',
            isVisible:
              steps.find(step => step.id === WizardSteps.insurance)?.tasks[0]?.status ===
              'COMPLETED',
            text: 'Organise your removal or van hire',
            openCard: WizardSteps.removals,
            iconBackground: 'bg-home',
            icon: <Removals className="mt-0.5 h-6 fill-white" />,
          },
          {
            id: 'utilities',
            isVisible:
              steps.find(step => step.id === WizardSteps.removals)?.tasks[0]?.status ===
              'COMPLETED',
            text: 'View your energy & broadband quotes',
            openCard: WizardSteps.getMoveReady,
            iconBackground: 'bg-home',
            icon: <Bulb className="mt-0.5 h-6 fill-white" />,
          },
          {
            id: 'store',
            isVisible: true,
            text: 'Make it home with the Home Store',
            link: ProductLinks.STORE.MAIN,
            iconBackground: 'bg-home',
            icon: <StoreBag className="ml-0.5 mt-0.5 h-6 fill-white" />,
          },
        ]
      : [
          {
            id: 'whatsapp',
            isVisible: true,
            text: 'Add us on WhatsApp 🙌',
            link: 'https://wa.me/447535742914',
            iconBackground: 'bg-[#27D045]',
            icon: <WhatsApp className="ml-0.5 mt-0.5 h-6 fill-white" />,
          },
          {
            id: 'conveyancing',
            isVisible:
              steps.find(step => step.id === WizardSteps.conveyancing)?.tasks[0]?.status !==
              'COMPLETED',
            text: 'Get instant conveyancing quotes',
            openCard: WizardSteps.conveyancing,
            iconBackground: 'bg-home',
            icon: <Conveyancing className="ml-0.5 mt-0.5 h-6 fill-white" />,
          },
          {
            id: 'inurance',
            isVisible:
              steps.find(step => step.id === WizardSteps.conveyancing)?.tasks[0]?.status ===
              'COMPLETED',
            text: 'Find the right insurance policy for you',
            openCard: WizardSteps.insurance,
            iconBackground: 'bg-home',
            icon: <Insurance className="mt-0.5 h-6 fill-white" />,
          },
          {
            id: 'removals',
            isVisible:
              steps.find(step => step.id === WizardSteps.insurance)?.tasks[0]?.status ===
              'COMPLETED',
            text: 'Organise your removal or van hire',
            openCard: WizardSteps.removals,
            iconBackground: 'bg-home',
            icon: <Removals className="mt-0.5 h-6 fill-white" />,
          },
          {
            id: 'utilities',
            isVisible:
              steps.find(step => step.id === WizardSteps.removals)?.tasks[0]?.status ===
              'COMPLETED',
            text: 'View your energy & broadband quotes',
            openCard: WizardSteps.getMoveReady,
            iconBackground: 'bg-home',
            icon: <Bulb className="mt-0.5 h-6 fill-white" />,
          },
          {
            id: 'store',
            isVisible: true,
            text: 'Make it home with the Home Store',
            link: ProductLinks.STORE.MAIN,
            iconBackground: 'bg-home',
            icon: <StoreBag className="ml-0.5 mt-0.5 h-6 fill-white" />,
          },
        ];

  useEffect(() => {
    const dismissedTips: Record<string, boolean> = JSON.parse(
      localStorage.getItem('dismissedTips') || '{}'
    );
    const availableTip = TIPS.find(tip => tip.isVisible && !dismissedTips[tip.id]);

    if (availableTip) {
      setVisibleTip(availableTip);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!moveType) return null;

  const handleDismiss = (id: string) => {
    setIsCollapsing(true);
    setTimeout(() => {
      const dismissedTips: Record<string, boolean> = JSON.parse(
        localStorage.getItem('dismissedTips') || '{}'
      );
      dismissedTips[id] = true;
      localStorage.setItem('dismissedTips', JSON.stringify(dismissedTips));
      setVisibleTip(null);
      setIsCollapsing(false);
    }, 300);
  };

  if (!visibleTip) return null;

  return (
    <div
      className={`flex w-full max-w-md flex-col items-start gap-4 rounded-2xl bg-white p-5 pt-4 shadow-homeLarge transition-all duration-300 ${
        isCollapsing ? 'max-h-0 overflow-hidden opacity-0' : 'max-h-96 opacity-100'
      }`}
    >
      <div className="flex w-full items-center justify-between">
        <h5 className="text-2xl font-medium">Quick tip</h5>
        <button className="p-1 underline" onClick={() => handleDismiss(visibleTip.id)}>
          Dismiss
        </button>
      </div>
      <a
        className="flex w-full cursor-pointer items-center gap-3"
        target="_blank"
        {...(!!visibleTip.link && { href: visibleTip.link })}
        {...(!!visibleTip.openCard && {
          onClick: () => {
            const card = document.getElementById(`${visibleTip.openCard}-header`);
            card?.click();
          },
        })}
        rel="noreferrer"
      >
        <div
          className={twMerge(
            `flex h-10 w-10 items-center justify-center rounded-full`,
            visibleTip.iconBackground
          )}
        >
          {visibleTip.icon}
        </div>
        <p className="text-lg">{visibleTip.text}</p>
      </a>
    </div>
  );
};
