// Global
import { useState, useEffect, FC } from 'react';

// Images
import { ProgressFrameOne, ProgressFrameTwo, ProgressFrameThree } from '@images/icons';

export const InProgress: FC = () => {
  const [currentFrame, setCurrentFrame] = useState<number>(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFrame(prevFrame => (prevFrame === 3 ? 1 : prevFrame + 1));
    }, 400);

    return () => clearInterval(interval);
  }, []);

  const renderSVG = (): JSX.Element | null => {
    switch (currentFrame) {
      case 1:
        return <ProgressFrameOne className="w-8 fill-white md:fill-primary" />;
      case 2:
        return <ProgressFrameTwo className="w-8 fill-white md:fill-primary" />;
      case 3:
        return <ProgressFrameThree className="w-8 fill-white md:fill-primary" />;
      default:
        return null;
    }
  };

  return <div aria-label="Task In Progress">{renderSVG()}</div>;
};
