import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Concierge } from '@components/ConciergeBlock';

import { selectProfile } from '@features/profile/profile';

import BundlesBackground from '@images/bundles_background.jpg';
import BundlesIcons from '@images/bundles_icons.svg';
import BundlesLogos from '@images/bundles_logos.webp';
import { Broadband } from '@images/icons';

import { convertAddressToSingleLine } from '@utils/convertAddress';

import { BroadbandWizard } from './BroadbandWizard';
import { NoAddressTemp } from './noAddressTemp';
import { AllProducts } from './ProductList';

export const ServicesBroadbandPage = () => {
  const profile = useSelector(selectProfile)?.value;
  const address = profile?.properties?.[0] ? convertAddressToSingleLine(profile.properties[0]) : '';
  const postcode = profile?.properties?.[0]?.postcode ?? '';

  const [minSpeed, setMinSpeed] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (!postcode) return <NoAddressTemp />;

  return (
    <>
      <header className="mx-auto my-4 flex w-full max-w-3xl flex-col items-center justify-center gap-8 px-5 pb-12 pt-8">
        <h2 className="flex flex-col items-center gap-2 text-center text-3xl font-medium sm:flex-row md:text-4xl">
          <Broadband className="h-14 fill-brand sm:hidden" />
          <span>Here are your</span>
          <Broadband className="hidden h-14 fill-brand sm:block" />
          <span>broadband deals</span>
        </h2>
        <h3 className="text-center text-xl font-medium">{address}</h3>
        <p className="text-center">
          Let's get your home connected. First, we need to figure out how much speed you need and
          how much you want to spend.
        </p>
      </header>
      <BroadbandWizard updateSpeed={(speed: any) => setMinSpeed(speed)} />
      <section id="product-list" className="pt-20">
        <AllProducts minSpeed={minSpeed} clearFilters={() => setMinSpeed(0)} />
      </section>
      <section className="mx-auto flex max-w-7xl flex-col px-8 lg:px-10">
        <div className=" my-16 grid grid-cols-1 overflow-hidden rounded-3xl bg-white shadow-homeLarge lg:grid-cols-2">
          <div className="col-span-1 flex flex-col items-start gap-6 p-7 md:p-14">
            <img
              src={BundlesIcons}
              alt="Bundle icons"
              width={300}
              height={100}
              aria-hidden
              className="max-w-full"
            />
            <h3 className="text-2xl font-medium md:text-4xl">
              Save time and money with a Home bills bundle
            </h3>
            <p className=" text-lg">
              One payment, all your bills sorted. Create a bundle of all your bills to save more and
              simplify your home admin with just one easy bill to manage. You can also add your
              council tax and a TV package too!
            </p>
            <img
              src={BundlesLogos}
              alt="Bundle logos: Vodafone, Sky, Netflix, E-on, United Utilities"
              width={375}
              height={100}
              className="max-w-full"
            />
            <a
              href="https://app.homebox.co.uk/home/setup-account"
              target="_blank"
              className={`font-aktiv mt-auto inline-block rounded-2xl bg-black px-10 py-3 text-center font-semibold text-white hover:bg-gray-700 md:px-12 md:py-4`}
              rel="noreferrer"
            >
              Explore bundles
            </a>
          </div>
          <div
            style={{ backgroundImage: `url(${BundlesBackground})` }}
            className="col-span-1 bg-cover bg-center bg-no-repeat pb-[60%]"
          />
        </div>
      </section>
      <Concierge />
    </>
  );
};
