import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Button } from '@components/Button';

import BackgroundImage from '@images/broadband_ai.png';
import Logos from '@images/broadband_wizard_logos.png';
import { Info, Plus, Snow } from '@images/icons';

import { Calculating } from './Calculating';

type RadioCardProps = {
  id: string;
  activeOption: string;
  onChange: (id: string) => void;
  label: string | number;
};

const RadioCard = ({ id, activeOption, onChange, label }: RadioCardProps) => {
  return (
    <div className="relative flex items-center justify-center rounded-xl bg-gray-50 px-4 py-3 font-medium text-gray-700">
      <input
        className="peer hidden"
        type="radio"
        id={id}
        checked={activeOption === id}
        onChange={() => onChange(id)}
      />
      <label
        className="absolute top-0 h-full w-full cursor-pointer rounded-xl border transition-all hover:border-home peer-checked:border-home peer-checked:bg-[rgba(235,55,84,0.2)]"
        htmlFor={id}
      >
        {' '}
      </label>
      <span className="pointer-events-none z-10">{label}</span>
    </div>
  );
};

type QuestionTitleProps = {
  title: string;
  description?: string;
};

const QuestionTitle = ({ title, description }: QuestionTitleProps) => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <div className={twMerge('flex w-full flex-col gap-4', !!description && 'pb-3')}>
      <h3 className="text-2xl font-medium">
        {title}{' '}
        {description && (
          <button onClick={() => setShowDescription(!showDescription)} className="ml-1 mt-2 p-1">
            <Info className="h-6 fill-primary" />
          </button>
        )}
      </h3>
      {showDescription && <p>{description}</p>}
    </div>
  );
};

type BroadbandWizardProps = {
  updateSpeed: (speed: number) => void;
};

export const BroadbandWizard = ({ updateSpeed }: BroadbandWizardProps) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [backgroundVisible, setBackgroundVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  // Form state
  const [peopleCount, setPeopleCount] = useState('people-1');
  const [highUsage, setHighUsage] = useState('stream-no');
  const [multipleDevices, setMultipleDevices] = useState('multiple-no');
  const [smartDevices, setSmartDevices] = useState('smart-no');
  const [smartDevicesCount, setSmartDevicesCount] = useState('smart-count-1');
  const [workFromHome, setWorkFromHome] = useState('wfh-no');
  const [isCalculating, setIsCalculating] = useState(false);

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
      setTimeout(() => {
        setBackgroundVisible(true);
      }, 10);
      setTimeout(() => {
        setModalVisible(true);
      }, 151);
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [modalIsOpen]);

  function closeModal() {
    setIsOpen(false);
    setBackgroundVisible(false);
    setModalVisible(false);
  }

  const handleOptionChange = (setter: (value: string) => void, value: string) => {
    setter(value);
    setIsCalculating(true);

    setTimeout(() => {
      setIsCalculating(false);
    }, 1000);
  };

  const submitForm = (speed: number) => {
    updateSpeed(speed);
    setHasSubmitted(true);
    closeModal();
  };

  const determineSpeed = () => {
    const BASIC_USAGE = 10;
    const HIGH_USE_MODIFIER = 10;
    const WFH_MODIFIER = 5;

    const TOTAL_USERS = parseInt(peopleCount.slice(-1));
    const TOTAL_DEVICES = multipleDevices === 'multiple-yes' ? 3 * TOTAL_USERS : 1 * TOTAL_USERS;

    const SMART_DEVICES = smartDevices === 'smart-yes' ? parseInt(smartDevicesCount.slice(-1)) : 0;
    const HIGH_USAGE_FLAG = highUsage === 'stream-yes';

    const costPerUserDevice =
      BASIC_USAGE +
      (HIGH_USAGE_FLAG ? HIGH_USE_MODIFIER : 0) +
      (workFromHome === 'wfh-yes' ? WFH_MODIFIER : 0);

    let totalMbps = TOTAL_DEVICES * costPerUserDevice;

    if (SMART_DEVICES > 0) {
      const totalSmartDevices = SMART_DEVICES * 5;
      totalMbps += totalSmartDevices;
    }

    let speedTier;

    if (totalMbps <= 15) {
      speedTier = {
        minSpeed: 15,
        title: '15 Mbps or below',
        description: 'Single person with minimal usage (email, shopping)',
      };
    } else if (totalMbps <= 50) {
      speedTier = {
        minSpeed: 20,
        title: '20-50 Mbps',
        description: 'Suitable for light use by a couple or small household.',
      };
    } else if (totalMbps <= 100) {
      speedTier = {
        minSpeed: 50,
        title: '50-100 Mbps',
        description: 'For moderate use in a small to medium household.',
      };
    } else if (totalMbps <= 250) {
      speedTier = {
        minSpeed: 100,
        title: '100-250 Mbps',
        description: 'For heavy usage in medium households or with many devices.',
      };
    } else if (totalMbps <= 500) {
      speedTier = {
        minSpeed: 250,
        title: '250-500 Mbps',
        description: 'For heavy usage in large households.',
      };
    } else {
      speedTier = {
        minSpeed: 500,
        title: '500 Mbps or more',
        description: 'For very large households with intensive internet usage.',
      };
    }

    return {
      ...speedTier,
    };
  };

  const { title, description, minSpeed } = determineSpeed();

  return (
    <>
      <section className="mx-auto flex w-full max-w-7xl flex-col gap-10 px-5">
        <div className="mx-auto flex w-full overflow-hidden rounded-3xl bg-white shadow-homeLarge">
          <div className="flex w-full flex-col gap-8 p-10">
            <div className="flex w-full justify-between gap-10">
              <div className="flex flex-col gap-4">
                <h3 className="text-left text-2xl font-medium">
                  {hasSubmitted
                    ? `Broadband Wizard results: ${title}`
                    : 'Not sure how much broadband speed you need? Meet Broadband Wizard.'}
                </h3>
                <p className="text-lg">
                  Our Broadband Wizard is powered by HomeAI to help find you the speed you need.
                </p>
              </div>
              <Snow className="h-20 fill-brand" />
            </div>
            <div className="flex flex-col-reverse items-center justify-start gap-10 lg:flex-row">
              <Button onClick={() => setIsOpen(true)} customStyles="!max-w-72">
                {hasSubmitted ? 'Update my wizard' : 'Get started'}
              </Button>
              <img src={Logos} alt="Broadband Wizard" className="w-full max-w-[498px]" />
            </div>
          </div>
          <div
            className="min-w-sm flex bg-cover bg-center bg-no-repeat md:basis-[315px]"
            style={{ backgroundImage: `url(${BackgroundImage})` }}
          ></div>
        </div>
      </section>
      {modalIsOpen && (
        <div
          className={twMerge(
            'fixed left-0 top-0 z-[5000] flex h-screen w-screen items-center justify-center bg-black bg-opacity-50 p-5 opacity-0 transition-all duration-200',
            backgroundVisible && 'opacity-100'
          )}
          onClick={closeModal}
        >
          <div
            onClick={e => e.stopPropagation()}
            className={twMerge(
              'relative z-[5001] max-h-[calc(100vh-40px)] w-full max-w-[calc(1000px-40px)] scale-90 rounded-3xl bg-white p-5 opacity-0 shadow-homeLarge md:p-8',
              modalVisible && 'animate-popIn'
            )}
          >
            <button
              className="absolute -right-5 -top-5 flex h-10 w-10 items-center justify-center rounded-full bg-primary"
              onClick={closeModal}
            >
              <Plus className="h-9 rotate-45 fill-white" />
            </button>
            <div className="flex max-h-[calc(100vh-100px)] flex-col gap-8 overflow-y-auto pb-5">
              <div className="flex flex-col gap-4">
                <QuestionTitle title="How many people are in your household?" />
                <div className="grid grid-cols-5 justify-center gap-4">
                  {Array.from({ length: 5 }, (_, i) => (
                    <RadioCard
                      key={`people-${i + 1}`}
                      label={i + 1 === 5 ? '5+' : i + 1}
                      id={`people-${i + 1}`}
                      activeOption={peopleCount}
                      onChange={id => handleOptionChange(setPeopleCount, id)} // Modified
                    />
                  ))}
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <QuestionTitle
                  title="Do members of your household frequently use multiple devices at once?"
                  description="For example using your phone whilst watching Netflix at the same time."
                />
                <div className="grid grid-cols-2 justify-center gap-4">
                  <RadioCard
                    label="Yes"
                    id="multiple-yes"
                    activeOption={multipleDevices}
                    onChange={id => handleOptionChange(setMultipleDevices, id)} // Modified
                  />
                  <RadioCard
                    label="No"
                    id="multiple-no"
                    activeOption={multipleDevices}
                    onChange={id => handleOptionChange(setMultipleDevices, id)} // Modified
                  />
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <QuestionTitle
                  title="Do you stream 4K TV or play online video games?"
                  description="Does anyone in your household use the internet for high bandwidth activities?"
                />
                <div className="grid grid-cols-2 justify-center gap-4">
                  <RadioCard
                    label="Yes"
                    id="stream-yes"
                    activeOption={highUsage}
                    onChange={id => handleOptionChange(setHighUsage, id)} // Modified
                  />
                  <RadioCard
                    label="No"
                    id="stream-no"
                    activeOption={highUsage}
                    onChange={id => handleOptionChange(setHighUsage, id)} // Modified
                  />
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <QuestionTitle
                  title="Do you have any smart devices connected?"
                  description="Such as Smart TVs, Security Cameras, Speakers, etc."
                />
                <div className="grid grid-cols-2 justify-center gap-4">
                  <RadioCard
                    label="Yes"
                    id="smart-yes"
                    activeOption={smartDevices}
                    onChange={id => handleOptionChange(setSmartDevices, id)} // Modified
                  />
                  <RadioCard
                    label="No"
                    id="smart-no"
                    activeOption={smartDevices}
                    onChange={id => handleOptionChange(setSmartDevices, id)} // Modified
                  />
                </div>
              </div>

              {smartDevices === 'smart-yes' && (
                <div className="flex flex-col gap-4">
                  <h3 className="text-2xl font-medium">
                    How many smart devices do you have connected?
                  </h3>
                  <div className="grid grid-cols-5 justify-center gap-4">
                    {Array.from({ length: 5 }, (_, i) => (
                      <RadioCard
                        key={`smart-count-${i + 1}`}
                        label={i + 1 === 5 ? '5+' : i + 1}
                        id={`smart-count-${i + 1}`}
                        activeOption={smartDevicesCount}
                        onChange={id => handleOptionChange(setSmartDevicesCount, id)} // Modified
                      />
                    ))}
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-4">
                <h3 className="text-2xl font-medium">
                  Does anyone in the household work from home?
                </h3>
                <div className="grid grid-cols-2 justify-center gap-4">
                  <RadioCard
                    label="Yes"
                    id="wfh-yes"
                    activeOption={workFromHome}
                    onChange={id => handleOptionChange(setWorkFromHome, id)} // Modified
                  />
                  <RadioCard
                    label="No"
                    id="wfh-no"
                    activeOption={workFromHome}
                    onChange={id => handleOptionChange(setWorkFromHome, id)} // Modified
                  />
                </div>
              </div>

              <div className="flex flex-col items-center gap-2 py-5">
                {isCalculating ? (
                  <div className="flex min-h-[222px] flex-col items-center justify-center gap-3 py-8">
                    <Calculating />
                    <span className="text-center text-xl font-medium">Calculating...</span>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center gap-3">
                    <h4 className="text-2xl font-medium">Your results</h4>

                    <h5 className="text-2xl">
                      Recommended speed: <span className="font-medium">{title}</span>
                    </h5>
                    <p className="text-lg">{description}</p>
                    <div className="mt-3 max-w-lg rounded-xl border border-yellow-400 bg-yellow-100">
                      <p className="flex items-center justify-center gap-4 p-4 text-yellow-800">
                        <Info className="inline-block h-10 fill-yellow-800" />
                        Please note that the broadband speed requirements tool is in beta and the
                        estimate given may not be accurate.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-center">
                <Button onClick={() => submitForm(minSpeed)}>Find deals</Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
