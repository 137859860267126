import { twMerge } from 'tailwind-merge';

interface RadioCardProps {
  id: string;
  activeOption: string;
  onChange: () => void;
  label: string | number;
}

export const RadioCard: React.FC<RadioCardProps> = ({ id, activeOption, onChange, label }) => {
  const isActive = activeOption === id;

  return (
    <div className="relative flex items-center justify-center rounded-xl bg-gray-50 px-4 py-3 text-center font-medium">
      <input
        className="peer hidden"
        type="radio"
        id={id}
        checked={activeOption === id}
        onChange={onChange}
      />
      <label
        className={twMerge(
          'absolute top-0 h-full w-full cursor-pointer rounded-xl border',
          isActive && 'border-home bg-[rgba(235,55,84,0.2)]'
        )}
        htmlFor={id}
      >
        {' '}
      </label>
      <span className="pointer-events-none z-10">{label}</span>
    </div>
  );
};
