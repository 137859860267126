import React from 'react';

import { HomeboxEnergyProduct } from '@hooks/useEnergyComparison';

export const Deal = React.memo(({ supplier, estimatedCost }: HomeboxEnergyProduct) => {
  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex flex-col items-center justify-start gap-6 sm:flex-row md:gap-10">
        <div
          style={{
            backgroundImage: `url(https://products.homebox.io/api/v3/energy-supplier-logos/${supplier.logo})`,
          }}
          className="h-14 w-24 rounded-lg bg-contain bg-center bg-no-repeat"
        />
        <div className="flex items-end gap-1 font-heading font-medium leading-none">
          <span className="-mb-0.5 text-3xl md:-mb-1">£{(estimatedCost / 12).toFixed(2)}</span>
          <span className="text-gray-600">Per month</span>
        </div>
      </div>
    </div>
  );
});
