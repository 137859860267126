import { ConveyancingSignpost } from '@components/Signpost';

import { BlogLinks } from '@constants/index';

import { ContentBlock } from '../../components/ContentBlock';

export const Conveyancing = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:flex-row md:items-start md:gap-14 md:px-10">
        <div id="column" className="flex flex-col gap-10 md:mt-5 md:basis-1/2 md:gap-14">
          <ConveyancingSignpost />
        </div>
        <div id="column" className="flex flex-col gap-10 md:basis-1/2 md:gap-14">
          <hr className="w-full border-stone-200 md:hidden" />
          <ContentBlock
            title="What to expect"
            content="Ease of instruction, timely updates on your case, no nasty surprises."
            link={BlogLinks.CONVEYANCING}
            buttonText="Read our guide to conveyancing"
            showBoxOnDesktop
          />
        </div>
      </div>
    </>
  );
};
