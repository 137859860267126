export const LazyImage = ({
  image,
  index,
  currentIndex,
}: {
  image: string;
  index: number;
  currentIndex: number;
}) => {
  const shouldLoad = Math.abs(currentIndex - index) <= 2; // Adjust preloading threshold
  return shouldLoad ? (
    <div
      className="absolute h-full w-full bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${image})` }}
      data-testid={`slide-${index}`}
    />
  ) : (
    <div>Loading...</div>
  );
};
