import { twMerge } from 'tailwind-merge';

interface ContentBlockProps {
  title: string;
  content?: string;
  link: string;
  buttonText: string;
  showBoxOnDesktop?: boolean;
  children?: React.ReactNode;
}

export const ContentBlock = ({
  title,
  content,
  link,
  buttonText,
  showBoxOnDesktop,
  children,
}: ContentBlockProps) => (
  <div
    className={twMerge(
      'flex w-full max-w-xl flex-col gap-4 rounded-3xl border-primary',
      showBoxOnDesktop && 'md:shadow-homeLargeLight md:mt-5 md:border-2 md:p-8'
    )}
  >
    <h4 className="text-2xl font-medium">{title}</h4>
    {content && <p className="text-lg font-light">{content}</p>}
    {!!children && children}
    {!!link && !!buttonText && (
      <a
        href={link}
        target="_blank"
        className="text-lg underline hover:no-underline"
        rel="noreferrer"
      >
        {buttonText}
      </a>
    )}
  </div>
);
