import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import {
  BuyerProtectionSignpost,
  SellerProtectionSignpost,
  Signpost,
  TenantsInsuranceSignpost,
} from '@components/Signpost';

import { selectProfile } from '@features/profile/profile';
import { MoveType } from '@features/profile/profile.types';

import { Info, Snow } from '@images/icons';

import { useWizard } from '../MoveWizard/wizards';

export enum WizardSteps {
  findProperty = 'FIND_PROPERTY',
  conveyancing = 'CONVEYANCING',
  contracts = 'CONTRACTS',
  insurance = 'INSURANCE',
  exchange = 'EXCHANGE',
  removals = 'REMOVALS',
  getMoveReady = 'MOVE_READY',
  complete = 'COMPLETE',
}

const NextStepSignposts: any = {
  [MoveType.BUYER]: {
    [WizardSteps.findProperty]: {
      title: 'Instruct a conveyancer and track your progress',
      subtitle: 'Next step',
      text: 'Get an instant online quote and instruct a conveyancer with Home.',
      buttonText: 'Instruct a conveyancer',
      openCard: WizardSteps.conveyancing,
      showBell: true,
    },
    [WizardSteps.conveyancing]: {
      title: 'Instruct a conveyancer and track your progress',
      subtitle: 'Next step',
      text: 'Get an instant online quote and instruct a conveyancer with Home.',
      buttonText: 'Instruct a conveyancer',
      openCard: WizardSteps.conveyancing,
      showBell: true,
    },
    [WizardSteps.contracts]: {
      title: 'Take out buildings insurance before you exchange',
      subtitle: 'Next step',
      text: 'Get a quote quickly online from over 50 UK insurance providers.',
      buttonText: 'Get an insurance quote',
      openCard: WizardSteps.insurance,
      showBell: true,
    },
    [WizardSteps.insurance]: {
      title: 'Take out buildings insurance before you exchange',
      subtitle: 'Next step',
      text: 'Get a quote quickly online from over 50 UK insurance providers.',
      buttonText: 'Get an insurance quote',
      openCard: WizardSteps.insurance,
      showBell: true,
    },
    [WizardSteps.exchange]: {
      title: 'Book your removals or van hire',
      subtitle: 'Next step',
      text: "Home's got lorry loads of choice. Pick one of our reliable partners, and book them in.",
      buttonText: 'Organise your removal',
      openCard: WizardSteps.removals,
      showBell: true,
    },
    [WizardSteps.removals]: {
      title: 'Book your removals or van hire',
      subtitle: 'Next step',
      text: "Home's got lorry loads of choice. Pick one of our reliable partners, and book them in.",
      buttonText: 'Organise your removal',
      openCard: WizardSteps.removals,
      showBell: true,
    },
    [WizardSteps.getMoveReady]: {
      title: 'Set up your utilities',
      subtitle: 'Next step',
      text: 'Save time and money. View your energy & broadband quotes and switch in minutes.',
      buttonText: 'Set up utilities',
      openCard: WizardSteps.getMoveReady,
      showBell: true,
    },
  },
  [MoveType.SELLER]: {
    [WizardSteps.findProperty]: {
      title: 'Instruct a conveyancer and track your progress',
      subtitle: 'Next step',
      text: 'Get an instant online quote and instruct a conveyancer with Home.',
      buttonText: 'Instruct a conveyancer',
      openCard: WizardSteps.conveyancing,
      showBell: true,
    },
    [WizardSteps.conveyancing]: {
      title: 'Instruct a conveyancer and track your progress',
      subtitle: 'Next step',
      text: 'Get an instant online quote and instruct a conveyancer with Home.',
      buttonText: 'Instruct a conveyancer',
      openCard: WizardSteps.conveyancing,
      showBell: true,
    },
    [WizardSteps.contracts]: {
      title: 'Insurance',
      subtitle: 'Next step',
      text: 'Get a quote quickly online from over 50 UK insurance providers.',
      buttonText: 'Get an insurance quote',
      openCard: WizardSteps.insurance,
      showBell: true,
    },
    [WizardSteps.insurance]: {
      title: 'Insurance',
      subtitle: 'Next step',
      text: 'Get a quote quickly online from over 50 UK insurance providers.',
      buttonText: 'Get an insurance quote',
      openCard: WizardSteps.insurance,
      showBell: true,
    },
    [WizardSteps.exchange]: {
      title: 'Book your removals or van hire',
      subtitle: 'Next step',
      text: "Home's got lorry loads of choice. Pick one of our reliable partners, and book them in.",
      buttonText: 'Organise your removal',
      openCard: WizardSteps.removals,
      showBell: true,
    },
    [WizardSteps.removals]: {
      title: 'Book your removals or van hire',
      subtitle: 'Next step',
      text: "Home's got lorry loads of choice. Pick one of our reliable partners, and book them in.",
      buttonText: 'Organise your removal',
      openCard: WizardSteps.removals,
      showBell: true,
    },
    [WizardSteps.getMoveReady]: {
      title: 'Set up your utilities',
      subtitle: 'Next step',
      text: 'Save time and money. View your energy & broadband quotes and switch in minutes.',
      buttonText: 'Set up utilities',
      openCard: WizardSteps.getMoveReady,
      showBell: true,
    },
  },
  [MoveType.TENANCY]: {
    [WizardSteps.findProperty]: {
      title: 'Book your removals or van hire',
      subtitle: 'Next step',
      text: "Home's got lorry loads of choice. Pick one of our reliable partners, and book them in.",
      buttonText: 'Organise your removal',
      openCard: WizardSteps.removals,
      showBell: true,
    },
    [WizardSteps.insurance]: {
      title: 'Book your removals or van hire',
      subtitle: 'Next step',
      text: "Home's got lorry loads of choice. Pick one of our reliable partners, and book them in.",
      buttonText: 'Organise your removal',
      openCard: WizardSteps.removals,
      showBell: true,
    },
    [WizardSteps.removals]: {
      title: 'Book your removals or van hire',
      subtitle: 'Next step',
      text: "Home's got lorry loads of choice. Pick one of our reliable partners, and book them in.",
      buttonText: 'Organise your removal',
      openCard: WizardSteps.removals,
      showBell: true,
    },
    [WizardSteps.getMoveReady]: {
      title: 'Set up your utilities',
      subtitle: 'Next step',
      text: 'Save time and money. View your energy & broadband quotes and switch in minutes.',
      buttonText: 'Set up utilities',
      openCard: WizardSteps.getMoveReady,
      showBell: true,
    },
  },
};

export const MyServices = () => {
  const { value: profile } = useSelector(selectProfile);

  const steps = useWizard();

  const tasks = steps.flatMap(step => step.tasks);
  const firstIncompleteStep = tasks.findIndex(task => task.status !== 'COMPLETED');
  const index = steps.findIndex(step => step.id === tasks[firstIncompleteStep]?.step);
  const nextStep = steps[index];

  const moveType = profile?.properties?.[0]?.status;

  if (!moveType || !nextStep) return null;

  const signpostData = NextStepSignposts[moveType][nextStep.id] || null;

  return (
    <section
      id="my-services"
      className="mx-auto flex max-w-6xl flex-col items-center gap-8 px-5 py-28"
    >
      <header className="flex w-full max-w-2xl justify-around gap-8">
        <div className="flex flex-col items-start gap-2">
          <h2 className="text-2xl font-medium">My Services</h2>
          <p>
            The services you need, when you need them. Powered by{' '}
            <span className="font-medium">Home</span> <span className="font-thin">ai</span>
          </p>
        </div>
        <div className="basis-[84px]">
          <Snow className="w-14 sm:w-20" />
        </div>
      </header>
      <div className="flex items-center gap-2 rounded-full bg-white py-1 pl-2 pr-3">
        <Info className="h-5 w-5" />
        <span className="text-sm font-medium">We're using ai to help movers</span>
      </div>
      <div
        className={twMerge(
          'grid grid-cols-1 items-start gap-12 py-8',
          !!signpostData && 'md:grid-cols-2'
        )}
      >
        <div className="rounded-3xl bg-white p-6">
          {moveType === MoveType.BUYER && <BuyerProtectionSignpost />}
          {moveType === MoveType.SELLER && <SellerProtectionSignpost />}
          {moveType === MoveType.TENANCY && <TenantsInsuranceSignpost />}
        </div>
        {!!signpostData && (
          <div className="rounded-3xl bg-white p-6">
            <Signpost {...signpostData} />
          </div>
        )}
      </div>
    </section>
  );
};
