import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { WizardSteps } from '@constants/index';

import { selectProfile } from '@features/profile/profile';
import { MoveType } from '@features/profile/profile.types';

import {
  MapPin,
  Conveyancing as ConveyancingIcon,
  Insurance as InsuranceIcon,
  Complete as CompleteIcon,
  Payment,
  Removals as RemovalsIcon,
  Bulb,
  Pound,
} from '@images/icons';

import { convertAddressToSingleLine } from '@utils/convertAddress';

import { Complete } from './cards/Complete';
import { Conveyancing } from './cards/Conveyancing';
import { Exchange } from './cards/Exchange';
// import { Finances } from './cards/Finances';
import { Finances } from './cards/Finances';
import { FindAProperty } from './cards/FindAProperty';
import { GetMoveReady } from './cards/GetMoveReady';
import { Insurance } from './cards/Insurance';
import { Removals } from './cards/Removals';
import { TenantsInsurance } from './cards/TenantsInsurance';

export const useWizard = () => {
  const { value: profile } = useSelector(selectProfile);
  const [searchParams] = useSearchParams();

  const firstProperty = profile?.properties?.[0];
  const propertyId = firstProperty?.propertyId ?? '';
  const status = firstProperty?.status ?? '';
  const address = firstProperty ? convertAddressToSingleLine(firstProperty) : '';

  if (status === MoveType.TENANCY || searchParams.get('renterWizard') === 'true')
    return [
      {
        id: WizardSteps.findProperty,
        icon: <MapPin className="h-10 w-10 fill-primary" />,
        title: 'Find a property',
        content: <FindAProperty moveType={status} />,
        tasks:
          firstProperty?.tasks?.filter(task => task.step === WizardSteps.findProperty) ?? [],
        propertyId,
      },
      {
        id: WizardSteps.insurance,
        icon: <InsuranceIcon className="h-10 w-10 fill-primary" />,
        title: 'Tenants Insurance',
        content: <TenantsInsurance moveType={status} />,
        tasks:
          firstProperty?.tasks?.filter(task => task.step === WizardSteps.insurance) ?? [],
        propertyId,
      },
      {
        id: WizardSteps.removals,
        icon: <RemovalsIcon className="h-10 w-10 fill-primary" />,
        title: 'Removals',
        content: <Removals moveType={status} />,
        tasks:
          firstProperty?.tasks?.filter(task => task.step === WizardSteps.removals) ?? [],
        propertyId,
      },
      {
        id: WizardSteps.getMoveReady,
        icon: <Bulb className="h-10 w-10 fill-primary" />,
        title: 'Utilities',
        content: <GetMoveReady />,
        tasks:
          firstProperty?.tasks?.filter(task => task.step === WizardSteps.getMoveReady) ?? [],
        propertyId,
      },
      {
        id: WizardSteps.complete,
        icon: <CompleteIcon className="w-14 fill-primary" />,
        title: 'Move In',
        content: <Complete moveType={status} address={address} />,
        tasks:
          firstProperty?.tasks?.filter(task => task.step === WizardSteps.complete) ?? [],
        propertyId,
      },
    ];

  // Buyers & Sellers
  return [
    {
      id: WizardSteps.findProperty,
      icon: <MapPin className="h-10 w-10 fill-primary" />,
      title: 'Find a property',
      content: <FindAProperty moveType={status} />,
      tasks:
        firstProperty?.tasks?.filter(task => task.step === WizardSteps.findProperty) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.conveyancing,
      icon: <ConveyancingIcon className="h-10 w-10 fill-primary" />,
      title: 'Conveyancing',
      content: <Conveyancing />,
      tasks:
        firstProperty?.tasks?.filter(task => task.step === WizardSteps.conveyancing) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.contracts,
      icon: <Pound className="h-10 w-10 fill-primary" />,
      title: 'Finances',
      content: <Finances />,
      tasks: firstProperty?.tasks?.filter(task => task.step === WizardSteps.contracts) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.insurance,
      icon: <InsuranceIcon className="h-10 w-10 fill-primary" />,
      title: 'Insurance',
      content: <Insurance moveType={status} />,
      tasks: firstProperty?.tasks?.filter(task => task.step === WizardSteps.insurance) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.exchange,
      icon: <Payment className="h-10 w-10 fill-primary" />,
      title: 'Exchange',
      content: <Exchange moveType={status} />,
      tasks: firstProperty?.tasks?.filter(task => task.step === WizardSteps.exchange) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.removals,
      icon: <RemovalsIcon className="h-10 w-10 fill-primary" />,
      title: 'Removals',
      content: <Removals moveType={status} />,
      tasks: firstProperty?.tasks?.filter(task => task.step === WizardSteps.removals) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.getMoveReady,
      icon: <Bulb className="h-10 w-10 fill-primary" />,
      title: 'Utilities',
      content: <GetMoveReady />,
      tasks:
        firstProperty?.tasks?.filter(task => task.step === WizardSteps.getMoveReady) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.complete,
      icon: <CompleteIcon className="h-10 w-8 fill-primary" />,
      title: 'Complete',
      content: <Complete moveType={status} address={address} />,
      tasks: firstProperty?.tasks?.filter(task => task.step === WizardSteps.complete) ?? [],
      propertyId,
    },
  ];
};
