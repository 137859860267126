import React from 'react';

export function toggleSwitch(props: object, name: string, component: React.JSX.Element, required: boolean) {
  return <label className="inline-flex cursor-pointer items-center gap-4">
    <input
      type="checkbox"
      id={name}
      name={name}
      className="peer sr-only"
      required={required}
      {...props}
    />
    <div
      className="peer relative h-7 min-w-[58px] basis-[58px] rounded-full bg-red-50 shadow-sm after:absolute after:left-[-1px]
           after:top-[-2.5px] after:h-8 after:w-8 after:rounded-full after:border after:border-red-400 after:bg-white after:transition-all
           after:content-[''] focus:outline-none peer-checked:bg-green-50 border-[0.5px] border-gray-400 bg-opacity-50 peer-checked:after:translate-x-full
           peer-checked:after:border-green-700"></div>
    <span className="text-sm leading-snug text-[#333333]">
          {component}
        </span>
  </label>;
}