import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { ApiRoutes } from '@constants/index';

import { updateTaskStatus } from '@features/profile/profile';
import { TaskStatus } from '@features/profile/profile.types';
import { Task } from '@features/profile/profile.types';

import { usePatch } from '@hooks/usePatch';
import { useToast } from '@hooks/useToast';

import { CheckboxEmpty, CheckboxTick, Clipboard, Dash } from '@images/icons';

import { InProgress } from './InProgressAnimation';


interface ChecklistProps {
  tasks: Task[];
  propertyId: string;
}

export const Checklist = ({ tasks, propertyId }: ChecklistProps) => {
  const uncheckedTasks = tasks.filter(task => task.status === TaskStatus.NOT_STARTED);
  const completedTasks = tasks.filter(task => task.status === TaskStatus.COMPLETED);

  return (
    <div className="p-8 pb-10">
      <div className="shadow-homeLargeLight flex flex-col gap-6 rounded-2xl border-2 border-primary p-5">
        <Clipboard className="w-9 fill-primary" />
        <h4 className="text-3xl font-medium">My check list</h4>
        <div className="flex flex-col gap-4">
          {uncheckedTasks.map(task => (
            <ManualTask key={task.id} {...task} propertyId={propertyId} />
          ))}
          {completedTasks.map(task => (
            <ManualTask key={task.id} {...task} propertyId={propertyId} />
          ))}
        </div>
      </div>
    </div>
  );
};

const ManualTask = ({ uiName, status, id, propertyId, name }: any) => {
  const [taskStatus, setTaskStatus] = useState<TaskStatus>(TaskStatus.NOT_STARTED);
  const { dispatchErrorToast, dispatchSuccessToast } = useToast();
  const { postData } = usePatch(ApiRoutes.UPDATE_TASK(propertyId, id));

  const dispatch = useDispatch();

  useEffect(() => {
    setTaskStatus(status);
  }, [status]);

  const handleUpdateTask = async () => {
    const initialStatus = taskStatus;
    const newStatus =
      taskStatus === TaskStatus.COMPLETED ? TaskStatus.NOT_STARTED : TaskStatus.COMPLETED;

    try {
      setTaskStatus(TaskStatus.IN_PROGRESS);

      const response = await postData({
        taskId: id,
        name: name,
        status: newStatus,
      });

      if (!!response) {
        dispatchSuccessToast(`Task "${uiName}" updated successfully`);

        dispatch(
          updateTaskStatus({
            propertyId,
            taskId: id,
            newStatus,
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      setTaskStatus(initialStatus);
      dispatchErrorToast(`Something went wrong updating the task "${uiName}"`);
    }
  };

  return (
    <button
      className="flex cursor-pointer select-none items-center gap-2 disabled:cursor-default"
      onClick={() => handleUpdateTask()}
      aria-label={`Mark ${uiName} as ${taskStatus === TaskStatus.COMPLETED ? 'not completed' : 'complete'}`}
      aria-pressed={taskStatus === TaskStatus.COMPLETED}
    >
      <span className="flex w-10 items-center justify-center">{getIcon(taskStatus)}</span>
      <span
        className={twMerge(
          'text-primary',
          status === TaskStatus.COMPLETED && 'text-stone-400 line-through'
        )}
      >
        {uiName}
      </span>
    </button>
  );
};

const getIcon = (status: string) => {
  switch (status) {
    case TaskStatus.COMPLETED:
      return (
        <CheckboxTick
          className="ml-[5px] mt-[-7px] w-[25px] fill-stone-400"
          aria-label="Task Completed"
        />
      );
    case TaskStatus.IN_PROGRESS:
      return <InProgress />;
    case TaskStatus.NOT_STARTED:
    default:
      return <CheckboxEmpty className="w-5 fill-primary" aria-label="Task Not Started" />;
  }
};
