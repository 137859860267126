import { TenantsInsuranceSignpost } from '@components/Signpost';

import { BlogLinks, ProductLinks } from '@constants/index';

import InsuranceHeader from '@images/insurance-header.webp';
import InsuranceLogos from '@images/insurance-logos.webp';

import { ContentBlock } from '../../components/ContentBlock';

type Props = {
  moveType: string;
};

export const TenantsInsurance = ({ moveType }: Props) => {
  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:flex-row md:items-start md:gap-14 md:px-10">
        <div id="column" className="flex flex-col gap-10 md:mt-5 md:basis-1/2 md:gap-14">
          <TenantsInsuranceSignpost />
        </div>
        <div id="column" className="flex flex-col gap-10 md:basis-1/2 md:gap-14">
          <hr className="w-full border-stone-200 md:hidden" />
          <ContentBlock
            title="What to expect"
            content="If you're renting you will need to make sure you have contents insurance in place to protect your belongings."
            link={BlogLinks.TENANTS_INSURANCE}
            buttonText="Read our guide to insurance"
            showBoxOnDesktop
          />
        </div>
      </div>
      <hr className="w-full border-stone-200 pb-12" />
      <div className="flex flex-col gap-8 px-7 pb-12 md:flex-row md:px-10">
        <div className="md:basis-1/2">
          <img src={InsuranceHeader} alt="Insurance header" className="w-full md:basis-1/2" />
        </div>
        <div className="flex flex-col items-center gap-5 md:basis-1/2 md:items-start md:gap-6">
          <h4 className="text-center text-2xl font-medium leading-none md:text-left">
            Shop Home Security
          </h4>
          <p className="max-w-72 text-center text-lg md:max-w-max md:text-left">
            From new locks to security cameras, we have you covered.
          </p>
          <img src={InsuranceLogos} alt="Insurance logos" className="w-full max-w-56" />
          <a
            className="font-base w-full rounded-xl bg-primary px-8 py-3 text-center text-lg font-medium text-white sm:w-auto sm:px-12 md:w-full"
            href={ProductLinks.STORE.SECURITY || ProductLinks.STORE.MAIN}
            target="_blank"
            rel="noreferrer"
          >
            View Home Security
          </a>
        </div>
      </div>
    </>
  );
};
