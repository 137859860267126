import React, { useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { Complete, HouseBlank, TickIsolated } from '@images/icons';

import { Card } from './components/Card';
import { useWizard } from './wizards';

export const MoveWizard: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [hasActivatedAccordion, setHasActivatedAccordion] = useState<boolean>(false);

  const steps = useWizard();

  const toggleSection = (index: number) => {
    setActiveIndex(activeIndex === index ? 0 : index);
    setHasActivatedAccordion(true);
  };

  useEffect(() => {
    const tasks = steps.flatMap(step => step.tasks);
    const firstIncompleteStep = tasks.findIndex(task => task.status !== 'COMPLETED');
    const index = steps.findIndex(step => step.id === tasks[firstIncompleteStep]?.step);
    setActiveIndex(index === -1 ? steps.length - 1 : index);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tasks = steps.flatMap(step => step.tasks);
  const completedTasks = tasks.filter(task => task.status === 'COMPLETED');

  const percentageComplete = (completedTasks.length / tasks.length) * 100;

  return (
    <div className="flex w-full flex-col items-center gap-14 md:gap-20" id="move-wizard">
      <section className="mx-auto flex w-full max-w-3xl flex-col items-center gap-5 px-5 md:gap-8">
        <h2 className="mb-3 text-center text-3xl font-medium text-white md:mb-0 md:text-4xl">
          Manage your move
        </h2>
        <div className="flex items-center gap-4 rounded-full bg-secondary p-2 pr-4 text-sm font-medium text-primary md:text-base">
          <span className="rounded-full bg-primary px-4 py-1 font-heading text-lg font-medium text-white">
            Progress
          </span>
          <div>
            <span className="font-heading text-xl md:text-2xl">
              {percentageComplete.toFixed(0)}%
            </span>{' '}
            complete
          </div>
          <div className="flex items-center gap-1">
            <TickIsolated className="h-5 fill-primary" />
            <span>Sale agreed</span>
          </div>
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col items-start gap-2">
            <div className="h-10">
              <Complete className="w-20 fill-white" />
            </div>
            <span className="font-heading text-xl font-thin text-white">Offer Accepted</span>
          </div>
          <div className="flex flex-col items-end gap-2">
            <div className="h-10">
              <HouseBlank className="h-8 fill-white" />
            </div>
            <span className="font-heading text-xl font-thin text-white">Move in day</span>
          </div>
        </div>

        <div id="track" className="relative h-2 w-full rounded-full bg-white">
          <span
            className="absolute h-2 w-full rounded-full bg-primary"
            style={{ width: `${percentageComplete}%` }}
          />
          <span
            id="circle-1"
            className={twMerge(
              'absolute left-0 h-4 w-4 -translate-x-1.5 -translate-y-[4px] rounded-full',
              percentageComplete > 0 ? 'bg-primary' : 'bg-white'
            )}
          />
          <span
            id="circle-2"
            className={twMerge(
              'absolute left-1/2 h-4 w-4 -translate-x-1.5 -translate-y-[4px] rounded-full',
              percentageComplete > 50 ? 'bg-primary' : 'bg-white'
            )}
          />
          <span
            id="circle-3"
            className={twMerge(
              'absolute right-0 h-4 w-4 -translate-y-[4px] translate-x-1.5 rounded-full',
              percentageComplete === 100 ? 'bg-primary' : 'bg-white'
            )}
          />
          <span
            id="dot-1"
            className="absolute left-0 h-1.5 w-1.5 -translate-x-[1px] translate-y-[1px] rounded-full bg-home"
          />
          <span
            id="dot-2"
            className="absolute left-[16.6%] h-1.5 w-1.5 -translate-x-[1px] translate-y-[1px]  rounded-full bg-home"
          />
          <span
            id="dot-3"
            className="absolute left-[33.2%] h-1.5 w-1.5 -translate-x-[1px] translate-y-[1px]  rounded-full bg-home"
          />
          <span
            id="dot-4"
            className="absolute left-[50%] h-1.5 w-1.5 -translate-x-[1px] translate-y-[1px]  rounded-full bg-home"
          />
          <span
            id="dot-5"
            className="absolute left-[66.6%] h-1.5 w-1.5 -translate-x-[1px] translate-y-[1px]  rounded-full bg-home"
          />
          <span
            id="dot-6"
            className="absolute left-[83.2%] h-1.5 w-1.5 -translate-x-[1px] translate-y-[1px]  rounded-full bg-home"
          />
          <span
            id="dot-7"
            className="absolute right-0 h-1.5 w-1.5 translate-x-[1px] translate-y-[1px]  rounded-full bg-home"
          />
        </div>
      </section>

      <section className="flex w-full flex-col items-center gap-0">
        {steps.map((section, index) => (
          <Card
            {...section}
            key={index}
            hideBorder={activeIndex - 1 === index || index === steps.length - 1}
            isActive={activeIndex === index}
            onToggle={() => toggleSection(index)}
            topRadius={index === 0}
            bottomRadius={index === steps.length - 1}
            accordionActivated={hasActivatedAccordion}
          />
        ))}
      </section>
    </div>
  );
};
